import React, { Component } from 'react';
import {graphql} from 'gatsby'
import parse from 'html-react-parser';
import Layout from 'components/layout/internal';
import ReadTime from 'components/readTime'
import SEO from 'shared/seo'
import Share from 'components/share/'
import Sticky from "react-stickynode"
import style from 'templates/blog/blog.module.scss'

export default class BlogPostGet extends Component {
	
	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}blog/post/get/?_join=true&uri=${this.post.uri}`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					this.setState({
						content: data[0].body,
						title: data[0].title,
						data: data[0],
					});
				}
			})
			.catch(err => console.log);
	}

	constructor(props) {
		super(props);
		this.post = (this.props.data.allBlogPost && this.props.data.allBlogPost.edges.length ? JSON.parse(this.props.data.allBlogPost.edges[0].node.internal.content) : null);
		this.state = {
			content: (this.post ? this.post.body : 'Page Not Found'),
			title: (this.post ? this.post.title : 'Page Not Found'),
			data: (this.post ? this.post : null),
			description: (this.post.intro ? this.post.intro : null),
		}
		this.liveRefresh = this.liveRefresh.bind(this);
		this.metaData = this.props.data.site.siteMetadata;
		this.category = null;
		this.SEOimage = null;
		this.modifiedDate = null;
		this.pubDate = null;

		// Creating schema
		this.schema = {
			"@context": "http://schema.org",
			"@type": "BlogPosting",
			"headline": this.state.data.title,
			"datePublished": new Date(this.post.showDate).toDateString(),
			"mainEntityOfPage": "True",
			"publisher": {
				"@type": "Organization",
				"name": this.metaData.title,
			},
			"articleBody": this.state.data.body,
		}
	}

	componentDidMount() {
		this.liveRefresh();
		if (this.post.Topic && this.post.Topic.length > 0) {
			this.category = this.post.Topic[0].object;
		}
		let d = new Date(this.post.showDate);
		this.pubDate = `${pad(d.getMonth() + 1, 2)}/${pad(d.getDate(), 2)}/${d.getFullYear().toString().substr(-2)}`;
		if (this.state.data.intro) {
			this.schema.description = this.state.data.intro;
		} else {
			let description = this.state.data.body;
			function removeTags(str) {
				if ((str===null) || (str===''))
				return false;
				else
				str = str.toString();
				return str.replace( /(<([^>]+)>)/ig, '');
			}
			description = removeTags(description).trim();
			const maxLength = 37;
			if (description) {
				var wordCount = description.split(" ").length;
				description = description.split(" ").splice(0,maxLength).join(" ");
				if (wordCount > maxLength) {
					description += '...';
				}
				this.setState({
					description: description,
				})
			}
		}
		function pad(num, size) {
			num = num.toString();
			while (num.length < size) num = "0" + num;
			return num;
		}
		if (this.post.modified) {
			this.schema.datemodified = new Date(this.post.modified).toDateString();
			let d = new Date(this.post.modified);
			this.modifiedDate = `${pad(d.getMonth() + 1, 2)}/${pad(d.getDate(), 2)}/${d.getFullYear().toString().substr(-2)}`;
		}
		if (this.post.photo) {
			this.schema.image = this.metaData.siteUrl + this.post.photo;
			this.SEOimage = this.metaData.siteUrl + this.post.photo;
		}
	}

	render() {
		var twitterHandle = this.metaData.twitterHandle;
		var title = this.state.data.title;
		return (
			<>
			<Layout>
				<div className={[style.container, "grid-container"].join(' ')}>
					<div className="grid-x grid-margin-x">
						<div className="cell small-12 medium-3 show-for-medium">
							<Sticky enabled={true} top={50} bottomBoundary="#blogContent">
								<ReadTime className={style.readTime} data={this.state.data.body} prefix='' suffix='Min Read' />
								{this.category && <p className={style.postCategory}>{this.category.name}</p>}
								<div className={style.authorContainer}>
									<p className={(this.state.data.Author.length) ? style.author : 'hide'}>{(this.state.data.Author.length) ? parse(`<strong>Written By ${this.state.data.Author[0].object.firstName} ${this.state.data.Author[0].object.lastName}</strong>`) : ''}</p>
									<p className={style.dates}>Blog Posted: {this.pubDate}{this.modifiedDate && <span><br />Last Edited: {this.modifiedDate}</span>}</p>
								</div>
								<Share socialConfig={{
									twitterHandle,
									config: {
										url: `${this.metaData.siteUrl}${this.post.uri}`,
										title,
									},
								}} />
							</Sticky>
						</div>
						<div id="blogContent" className="cell small-12 medium-offset-1 medium-8">
							<h1 className={[style.postTitle].join(' ')}>{this.state.data.title}</h1>
							<div className="hide-for-medium">
								<ReadTime className={style.readTime} data={this.state.data.body} prefix='' suffix='Min Read' />
								{this.category && <p className={style.postCategory}>{this.category.name}</p>}
								<div className={style.authorContainer}>
									<p className={(this.state.data.Author.length) ? style.author : 'hide'}>{(this.state.data.Author.length) ? parse(`<strong>Written By ${this.state.data.Author[0].object.firstName} ${this.state.data.Author[0].object.lastName}</strong>`) : ''}</p>
									<p className={style.dates}>Blog Posted: {this.pubDate}{this.modifiedDate && <span><br />Last Edited: {this.modifiedDate}</span>}</p>
								</div>
								<Share socialConfig={{
									twitterHandle,
									config: {
										url: `${this.metaData.siteUrl}${this.post.uri}`,
										title,
									},
								}} />
							</div>
							<section className={[style.mainContent, "main-content"].join(' ')}>
								{parse(this.state.data.body)}
							</section>
						</div> 
					</div>
				</div>
			</Layout>
			<SEO title={`${this.state.data.title} | Blog`} description={this.state.description} image={this.SEOimage} schema={this.schema} />
			</>
		)
	}
}

export const query = graphql`
	query ($uri: String) {
	  allBlogPost(filter: {uri: {eq: $uri}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        internal {
	          content
	        }
	      }
	    }
	  }
	  site {
		siteMetadata {
		  siteUrl
		  twitterHandle
		  title
		  author
		}
	  }
	}
`