import React, {Component} from "react"
import PropTypes from "prop-types"
// Import neccessary site components, try to maintain alphabetical order
import Footer from 'components/footer/';
import Header from 'components/header/';
import Offcanvas from 'components/offcanvas/';
import SEO from 'shared/seo';
import style from './internal.module.scss';

export default class Basic extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		}
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
	}

	componentWillUnmount() {
		this.setState({isOpen: false});
	}

	open() {
		this.setState({isOpen: true});
	}

	close() {
		this.setState({isOpen: false});
	}

	render() {
		return (
			<>
			<SEO />
			<Offcanvas isOpen={this.state.isOpen} offcanvasOpen={this.open} offcanvasClose={this.close} position="right" onNavigationChange={this.props.onNavigationChange}>
				<Header opaque={true} offcanvasOpen={this.open} offcanvasClose={this.close} offcanvasState={this.state.isOpen} buttonContainerClass={style.buttonContainer} />
				<main className={style.main}>{this.props.children}</main>
				<Footer/>
			</Offcanvas>
			</>
		)
	}
}

Basic.propTypes = {
	children: PropTypes.node.isRequired,
}